import React, { Component } from "react";
import {
  Grid,
  Loader,
  Message,
  Nav,
  Navbar,
  Panel,
  Row,
  Col,
  Tag,
  Divider,
} from "rsuite";
import PhoneFillIcon from "@rsuite/icons/PhoneFill";

import logo from "./stack-store.png";

class Tracker extends Component {
  state = {
    uid: window.location.href.split("?")[1],
    data: null,
    loading: true,
  };

  componentDidMount = () => {
    var { uid } = this.state;
    if (uid) {
      //   fetch("http://localhost:8000/api/get-sale/" + uid, {
      fetch("https://api.salesmart.in/api/get-sale/" + uid, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            this.setState({ data, loading: false });
          } else {
            window.alert("Invalid tracking id");
          }
          this.setState({ data, loading: false });
        })
        .catch((error) => {
          window.alert("Invalid tracking id");
        });
    }
  };

  render() {
    var { uid, data, loading } = this.state;
    return (
      <div>
        <div>
          <Navbar>
            <Navbar.Brand href="https://salesmart.in">
              <img src={logo} style={{ width: "auto", height: "40px" }} />
            </Navbar.Brand>

            <Nav pullRight>
              <Nav.Item icon={<PhoneFillIcon />}>
                <a href="tel:919037063936">Help</a>
              </Nav.Item>
            </Nav>
          </Navbar>
          <br />

          <Panel header="Track your order" shaded>
            {!uid ? (
              <Message type="error">
                You don't have a valid tracking id, please click the link
                received via SMS or contact our customer support team{" "}
                <a href="tel:919037063936">
                  <PhoneFillIcon /> Call us
                </a>
              </Message>
            ) : (
              <div>
                {loading ? (
                  <Loader size="xl" />
                ) : data ? (
                  <div>
                    <h3>Order id: {data.id}</h3>
                    <Divider>Order Details</Divider>
                    <Grid fluid>
                      <Row>
                        <Col xs="12">Total</Col>
                        <Col xs="12">
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(
                            parseFloat(data.channel_rate) +
                              parseFloat(data.total_amount) +
                              parseFloat(data.cod_fee)
                          )}
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs="12">Payment type</Col>
                        <Col xs="12">
                          <Tag>
                            {data.cod ? "Cash on delivery" : "Pre paid"}
                          </Tag>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs="12">Status</Col>
                        <Col xs="12">
                          <Tag color="green">{data.status}</Tag>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs="12">Delivery date</Col>
                        <Col xs="12">
                          {data.delivery_date ?? "Yet to be prepared"}
                        </Col>
                      </Row>
                      <br />
                    </Grid>
                    <h2>Thank you for the purchase with SalesMart.In</h2>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </Panel>
        </div>
      </div>
    );
  }
}

export default Tracker;
