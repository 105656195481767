import logo from "./logo.svg";
import "./App.css";
import Tracker from "./Tracker";
import "rsuite/dist/rsuite.min.css";

function App() {
  return (
    <div>
      <Tracker />
    </div>
  );
}

export default App;
